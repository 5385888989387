@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'TwkEverett';
  src: local('TwkEverett'), url('app/fonts/TWKEverett-Regular-web.ttf') format('truetype');
  font-weight: 400;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0 !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* overflow hidden for ios */
.overflow-hidden-ios {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: -webkit-radial-gradient(white, black);
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

/* Change input the white color when use autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.draggable-cursor {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/openhand.cur'), all-scroll !important;
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
  cursor: -o-grab !important;
  cursor: -ms-grab !important;
  cursor: grab !important;
}

.draggable-cursor:active {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -o-grabbing !important;
  cursor: -ms-grabbing !important;
  cursor: grabbing !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
